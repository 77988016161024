import React, { useState } from "react"
import ModerateContent from "../../components/search/moderate-content/moderate-content"
import InnerSearchLayout from "../../components/layout/inner-search-layout/inner-search-layout"
import styles from "./index.module.scss"
import auth from "../../services/auth"

const ModerateMedia = props => {
  return (
    <InnerSearchLayout location={props.location} searchPath="searchPending">
      <ModerateContent />
    </InnerSearchLayout>
  )
}

export default ModerateMedia
